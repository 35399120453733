import axios from 'axios';
import {apiUrl} from './environment';
import TokenStorage from "./TokenStorage";

let transport;


export const configureTransport = (token = null) => {
	const accessToken = token || TokenStorage.getAccessToken();
    const options = accessToken ?
        {
            baseURL: apiUrl,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                access_token: accessToken,
            },
        }
        : { baseURL: apiUrl };
    transport = axios.create(options);
    return transport;
};

export const getTransport = () => transport || configureTransport();
